import "./style.css";
import Navbar from "../navbar";
import backImage from "../../assets/imgs/indicacoes/carneiros/baia-branca-beach-resort.jpg";
import wave from "../../assets/svg/wave.svg";
import waveTop from "../../assets/svg/waveTop.svg";

function Home() {
  return (
    <>
      <div className="navigation">
        <Navbar />
      </div>
      <div className="page">
        <div className="home">
          <div className="centerHome">
           
            <div className="textHome">
              <h5>BEM VINDOS AO HONÓRIO TUR</h5>
              <h1>
                Proporcionando momentos inesqueciveis !
              </h1>
              <a href="#contato">
                <button>QUER SABER MAIS?</button>
              </a>
            </div>
          </div>
          <div className="image">
            <div className="blackBack">
            </div>
            <img src={backImage} alt="" />
          </div>
        </div>
        <img src={waveTop} alt="" className="waveTop" />
      </div>
    </>
  );
}

export default Home;
