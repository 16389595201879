import { BrowserRouter as Router, Routes , Route } from "react-router-dom";
import Home from "./components/home";
import Conhecer from "./components/Conheca";
import Comentarios from "./components/comentarios";
import Indicacao from "./components/indicacoes";
import Contato from "./components/contato";
import Start from "./components/links"
import Frota from "./components/frota"
import "./style.css"
function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Start/>}/>
        <Route path="/frota" element={<Frota/>}/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
