import React, { useState } from "react";
import "./style.css";
import logo from "../../assets/svg/fndAzul.png";
import clients1 from "../../assets/imgs/imgclients/clients.jpg";
import clients2 from "../../assets/imgs/imgclients/clients2.jpg";
import clients3 from "../../assets/imgs/imgclients/clients3.jpg";
import clients4 from "../../assets/imgs/imgclients/clients4.jpg";
import clients5 from "../../assets/imgs/imgclients/clients5.jpg";
import clients6 from "../../assets/imgs/imgclients/clients6.jpg";
import clients7 from "../../assets/imgs/imgclients/clients7.jpeg"
import clients8 from "../../assets/imgs/imgclients/clients8.jpeg"
import clients9 from "../../assets/imgs/imgclients/clients9.jpeg"
import clients10 from "../../assets/imgs/imgclients/clients10.jpeg"



import { Swiper, SwiperSlide } from "swiper/react";

function Conhecer() {
  const clients = [
    { imge: clients7, key: 7 },
    { imge: clients1, key: 1 },
    { imge: clients2, key: 2 },
    { imge: clients3, key: 3 },
    { imge: clients4, key: 4 }, 
    { imge: clients5, key: 5 },
    { imge: clients6, key: 6 },
    { imge: clients8, key: 8 },
    { imge: clients9, key: 9 },
    { imge: clients10, key: 10 },

  ];
  const [quemsomos, setQuemsomos] = useState("Nossa empresa, especializada no setor do Turismo, destaca-se pela extensa experiência e dedicação à satisfação dos clientes. Priorizamos um elevado padrão de qualidade e profissionalismo em todos os serviços oferecidos. Possuímos uma frota própria de veículos, garantindo conforto superior aos nossos clientes.");
  const [politicas,setPoliticas] = useState("Através da melhoria contínua e da eficácia de nosso atendimento, trabalhando com responsabilidade, ética, serenidade e comprometimento com os requisitos do serviço para a satisfação do cliente e garantia de sua viagem.")
  const [missao,setMissao] = useState("Nossa missão é ir além do turismo convencional. Estamos empenhados em criar experiências que transcendam o ordinário, explorando as riquezas de Alagoas e Pernambuco de maneira autêntica e responsável. Nossa missão é tríplice, refletindo nossos compromissos fundamentais.")
 const [visao,setVisao] = useState("Nossa visão é ser a principal referência em turismo em Alagoas e Pernambuco, reconhecidos pela excelência em serviços, compromisso ambiental e contribuição para o desenvolvimento sustentável das comunidades locais. Almejamos criar experiências que vão além das expectativas, inspirando os viajantes a descobrir a magia única que cada destino oferece.")
 const [valores,setValores] = useState("Ética, comprometimento, responsabilidade, honestidade, Respeito")
 const [actquemsomos, setActQuemsomos] = useState("actquemsomos");
  const [actpoliticas,setActPoliticas] = useState("false")
  const [actmissao,setActtMissao] = useState("false")
 const [actvisao,setActVisao] = useState("false")
 const [actvalores,setActValores] = useState("false")
 const [texto,setTexto] = useState(quemsomos)
  return (
    <>
    <div className="d">
      <div className="centerConhecendo">
        <h1>Conheça</h1>
        <div className="containerConhecendoG">
          <div className="containerConhecendo">
            <img src={logo} className="logoConheca" />
            <h5>HONÓRIO TUR</h5>
          </div>
          <div className="textConhecer">
          <div className="contButtons">
          <ul>
            <button className={actquemsomos} onClick={()=>{ setTexto(quemsomos); setActQuemsomos("actquemsomos");setActPoliticas(""); setActtMissao("");setActVisao("");setActValores("")}}>
              <li>Quem Somos</li>
            </button>
            <button  className={actpoliticas} onClick={()=>{setTexto(politicas); setActPoliticas("actpolitica");setActQuemsomos(""); setActtMissao("");setActVisao("");setActValores("")}}>
              <li>Politica</li>
            </button>
            <button className={actvisao} onClick={()=>{setTexto(visao); setActPoliticas("");setActQuemsomos(""); setActtMissao("");setActVisao("actvisao");setActValores("")}} >
              <li>Visão</li>
            </button>
            <button className={actvalores} onClick={()=>{setTexto(valores);setActPoliticas("");setActQuemsomos(""); setActtMissao("");setActVisao("");setActValores("actvalores")}} >
              <li>Valores</li>
            </button>
          </ul>
          </div>
            <h4>
              {texto}
            </h4>
            <a href="#contato">
              <button className="curioso">Ficou curioso(a)?</button>
            </a>
          </div>
        </div>
        
      </div>
    </div>
    </>
  );
}

export default Conhecer;
