import React, { useState } from "react";
import "./style.css";
import waveUp from "../../assets/svg/upWave.svg";
import logo from "../../assets/svg/fndAzul.png";
import wavedown from "../../assets/svg/wavedown.svg";
function Contato() {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [href,setHref] = useState('https://wa.me/558298332106?text=olá%20meu%20nome%20é:%20'+JSON.stringify(nome)+'%0%20email%20é:%20'+JSON.stringify(email))
 
 
  const [inputValue, setInputValue] = useState('Prefiro não informar');
    const [inputCidade, setInputCidade] = useState('Prefiro não informar');
    const [inputInteresse, setInputInteresse] = useState('')
 
 
 
 
  const handleKeyPress = (e) => {
  
    if (e.key === ' ' || e.keyCode === 32) {
      // Substituir espaço por '%s'
      setInputValue((prevValue) => prevValue + '%20');
    } else {
      // Manter as outras teclas
      setInputValue((prevValue) => prevValue + e.key);
    }
  };
  const  validacionEmail = () =>{
   if (inputInteresse && inputValue === null) {
    console.log("nao sei")
   }else {
    console.log("ta funcionando")
   }
   console.log("sla man")
  }
  const handleKeyDown = (e) => {
    
    if (e.key === 'Backspace' || e.keyCode === 8) {
      // Remover a última letra no backspace
      setInputValue((prevValue) => prevValue.slice(0, -3));
    }
  };
  const handleCidadePress = (e) => {
    if (e.key === ' ' || e.keyCode === 32) {
      // Substituir espaço por '%s'
      setInputCidade((prevValue) => prevValue + '%20');
    } else {
      // Manter as outras teclas
      setInputCidade((prevValue) => prevValue + e.key);
    }

  };

  const handleCidadeDown = (e) => {
    if (e.key === 'Backspace' || e.keyCode === 8) {
      // Remover a última letra no backspace
      setInputCidade((prevValue) => prevValue.slice(0, -3));
    }
  };
 
 
 
 
  return (
    <>
    <div className="allCtt">
      <img src={waveUp} alt="" className="waveUp" />
      <div className="contato">
        <div className="textContato">
          <div>
            <img src={logo} alt="" />
          </div>
          <h1>
            Agradecemos sua visita! <br /> Entre em contato conosco preenchendo o formulário.
          </h1>
        </div>
        <div className="inputs">
          <form >
            <div>
              <input
                type="text"
                name="nome"
                onKeyPress={handleKeyPress}
                onKeyDown={handleKeyDown}
                placeholder="Digite seu nome..."
              />
            </div>

            <div>
              <input
                type="email"
                onKeyPress={handleCidadePress}
                onKeyDown={handleCidadeDown}
                placeholder="Sou da cidade..."
              />
            </div>

            <div></div>
            <a href={'https://api.whatsapp.com/send/?phone=5582996030242&text=Olá%20meu%20nome%20é%20'+JSON.stringify(inputValue)+',%20%20sou%20de%20'+JSON.stringify(inputCidade)}>enviar</a>
          </form>
        </div>
      </div>
      <img src={wavedown} alt="" className="wavedown" />
      <div className="copy">
        <h6>©copyright from honório tur</h6>
        <h6>
          Desenvolvido por
          <a href="https://github.com/lucas-fernando-santos"> Lucas Fernando</a>
        </h6>
      </div>
                </div>
    </>
  );
}

export default Contato;
