import React, { useState } from "react";
import "./style.css";
import expand from "../../assets/svg/expandMore.svg";
import barra1 from "../../assets/imgs/indicacoes/barra-de-sa-miguel/saomiguel1.jpeg";
import barra2 from "../../assets/imgs/indicacoes/barra-de-sa-miguel/barra-sao-miguel-vista-aerea.jpg";
import canyon1 from "../../assets/imgs/indicacoes/canyons-rio-sao-francisco/canyons1.jpeg";
import canyon2 from "../../assets/imgs/indicacoes/canyons-rio-sao-francisco/download.jpg";
import capitao1 from "../../assets/imgs/indicacoes/capitao-nikolas/capitao1.jpeg";
import capitao2 from "../../assets/imgs/indicacoes/capitao-nikolas/download.jpg";
import carneiros1 from "../../assets/imgs/indicacoes/carneiros/carneiros1.jpeg";
import carneiros2 from "../../assets/imgs/indicacoes/carneiros/baia-branca-beach-resort.jpg";
import corais1 from "../../assets/imgs/indicacoes/corais-beach-club/corais1.jpeg";
import corais2 from "../../assets/imgs/indicacoes/corais-beach-club/download.jpg";
import dunas1 from "../../assets/imgs/indicacoes/dunas-de marape/dunas1.jpeg";
import dunas2 from "../../assets/imgs/indicacoes/dunas-de marape/passeios-dunas-de-marape-maceio-capa.jpeg";
import foz1 from "../../assets/imgs/indicacoes/foz-sao-francisco/foz1.jpeg";
import foz2 from "../../assets/imgs/indicacoes/foz-sao-francisco/download.jpg";
import frances1 from "../../assets/imgs/indicacoes/frances/frances1.jpeg";
import frances2 from "../../assets/imgs/indicacoes/frances/download.jpg";
import gunga1 from "../../assets/imgs/indicacoes/gunga/gunga1.jpeg";
import gunga2 from "../../assets/imgs/indicacoes/gunga/download.jpg";
import hibiscus1 from "../../assets/imgs/indicacoes/hibiscus/hibiscus1.jpeg";
import hibiscus2 from "../../assets/imgs/indicacoes/hibiscus/f7.jpg";
import maragogi1 from "../../assets/imgs/indicacoes/maragogi/maragogi1.jpeg";
import maragogi2 from "../../assets/imgs/indicacoes/maragogi/download.jpg";
import paripueira1 from "../../assets/imgs/indicacoes/paripueira/paripueira1.jpeg";
import paripueira2 from "../../assets/imgs/indicacoes/paripueira/download.jpg";
import porto1 from "../../assets/imgs/indicacoes/porto-de-galinhas/porto1.jpeg";
import porto2 from "../../assets/imgs/indicacoes/porto-de-galinhas/download.jpg";
import saomiguel1 from "../../assets/imgs/indicacoes/sao-miguel-milagres/saomiguel1.jpeg";
import saomiguel2 from "../../assets/imgs/indicacoes/sao-miguel-milagres/download.jpg";

function Indicacao() {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
    document.body.style.overflow = "hidden"; // Trava o scroll
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = "auto"; // Libera o scroll
  };
  const indicacoes = [
    {
      name: "Barra de São Miguel",
      img1: barra1,
      img2: barra2,
      key: 1,
      dexription:" Localizada no litoral sul de Alagoas, é conhecida por suas belas praias, mar calmo e coqueirais. A Praia do Gunga é uma das atrações mais famosas da região, com falésias coloridas e piscinas naturais.",
    },
    {
      name: "Canyons do Rio São Francisco",
      img1: canyon1,
      img2: canyon2,
      key: 2,
      dexription:"Embora esteja localizado em Sergipe e não em Alagoas, é uma atração popular para quem visita a região nordeste do Brasil. Os cânions proporcionam um cenário espetacular, com falésias e formações rochosas ao longo do Rio São Francisco.",
    },
    {
      name: "Capitão Nikolas",
      img1: capitao1,
      img2: capitao2,
      key: 3,
      dexription:" É um restaurante localizado na Praia do Gunga, conhecido por sua culinária regional e vista panorâmica para o mar.",
    },
    {
      name: "Carneiros",
      img1: carneiros1,
      img2: carneiros2,
      key: 4,
      dexription:"Apesar de não ser em Alagoas, mas em Pernambuco, é uma praia deslumbrante, famosa por suas águas calmas e cristalinas, piscinas naturais e pela Igreja de São Benedito.",
    },
    {
      name: "Corais beach club",
      img1: corais1,
      img2: corais2,
      key: 5,
      dexription:"Localizado na Praia de Antunes, em Maragogi, é um clube de praia com estrutura para receber turistas, oferecendo atividades e comodidades à beira-mar."
    },
    {
      name: "Dunas de Marapé",
      img1: dunas1,
      img2: dunas2,
      key: 6,
      dexription:"Fica na foz do Rio Jequiá, em Alagoas. É um local com imensas dunas e passeios de buggy bastante populares.",
    },
    {
      name: "Foz do rio São Francisco",
      img1: foz1,
      img2: foz2,
      key: 7,
      dexription:"Onde o rio encontra o mar, este local oferece uma paisagem incrível e passeios de barco para explorar a região.",
    },
    {
      name: "Francês",
      img1: frances1,
      img2: frances2,
      key: 8,
      dexription:"Praia bastante conhecida e frequentada, possui boa estrutura turística, com barracas, bares e lojas.",
    },
    {
      name: "Gunga",
      img1: gunga1,
      img2: gunga2,
      key: 9,
      dexription:"Praia paradisíaca no litoral sul de Alagoas, conhecida pelas falésias coloridas, coqueirais e mar de águas claras.",
    },
    {
      name: "Hibiscus",
      img1: hibiscus1,
      img2: hibiscus2,
      key: 10,
      dexription:"É um bar e restaurante na Praia de Ipioca, em Maceió, com estrutura à beira-mar para turistas.",
    },
    {
      name: "Maragogi",
      img1: maragogi1,
      img2: maragogi2,
      key: 11,
      dexription:"Famosa pelas belas praias e piscinas naturais, é uma das principais atrações de Alagoas, conhecida como o Caribe brasileiro.",
    },
    {
      name: "Mar e cia Paripueira",
      img1: paripueira1,
      img2: paripueira2,
      key: 12,
      dexription:" Restaurante na Praia de Paripueira, oferece culinária típica e uma vista agradável para o mar.",
    },
    {
      name: "Porto de galinhas",
      img1: porto1,
      img2: porto2,
      key: 13,
      dexription:"Embora localizado em Pernambuco, é uma das praias mais famosas do Brasil, conhecida por suas piscinas naturais formadas por recifes e águas cristalinas.",
    },
    {
      name: "São Miguel dos Milagres",
      img1: saomiguel1,
      img2: saomiguel2,
      key: 14,
      dexription:"Conhecida por suas praias tranquilas e piscinas naturais, faz parte da Rota Ecológica, oferecendo um ambiente mais preservado e rústico.",
    },
  ];
  const [more, setMore] = useState("monstrar mais");
  const [move, setMove] = useState("");
  const [ok, setOk] = useState(true);
  const [size, setSize] = useState("backImagetrue");
  const [text, setText] = useState("");
  const [image, setimage] = useState("");
  const [image2, setimage2] = useState("");
  const [firstImg, setFirstImg] = useState(true);
  const [lastImg, setLastImg] = useState(false);
  const [description,setDescription] = useState("");
  const [btleft,setBtLeft] = useState(false);
  const [btRight,setBtRight] = useState(true);
  const setItens = () => {
    if (ok === true) {
      setMore("mostrar menos");
      setMove("move");
      setOk(false);
      setSize("backImageoff");
    } else {
      setSize("backImagetrue");
      setMore("mostrar mais");
      setMove("");
      setOk(true);
    }
  };
  return (
    <>
      <div className={size}>
        {isModalOpen && (
          <section id="gIndication">
            {btleft && (
              <button onClick={()=>{
                setBtLeft(false)
                setBtRight(true)
                setFirstImg(true)
                setLastImg(false)
              }} className="btAft">
          <img src={expand}/>
            </button>)
            }
            
            <button className="modalOn" onClick={closeModal}>
              {firstImg && (
                <img src={image2} alt="" className="imgMod"/>)
              }
              {lastImg && (
                <img src={image2} alt="" className="imgMod"/>)
              }
              <h2>{description}</h2>
        </button>
          </section>
        )}
        <div className="titleIndica">
          <h3>Temos indicações para você</h3>
        </div>
        <ul className="three-column-list">
          {indicacoes.map((item) => (
            <a href="#gIndication">
              <button
                onClick={() => {
                  setText(item.name);
                  setimage(item.img1);
                  setimage2(item.img2)
                  setDescription(item.dexription);
                  openModal();
                }}
              >
                <li>
                  <img src={item.img2} alt={item.name} />
                  <h4>{item.name}</h4>
                </li>
              </button>
            </a>
          ))}
        </ul>
      </div>
      <div className="contMore">
      <button onClick={setItens} className="btMore">
          <img src={expand} className={move} title={more}/>
        </button>
      </div>
    </>
  );
}

export default Indicacao;
