import React, { useState } from "react";
import logo from "../../assets/svg/logo.svg";
import menu from "../../assets/svg/menu.svg";
import close from "../../assets/svg/close.svg";
import whatsapp from "../../assets/svg/contatos/WhatsApp-Logo.wine.svg"
import instagram from "../../assets/svg/contatos/Instagram-Logo.wine.svg"

import "./style.css";

function Navbar() {
  const [modal, setModal] = useState(false);
  return (
    <>
    <div className="allModal">
      {modal && (
        <div className="modal">
          
          <button
            onClick={() => {
              setModal(false);
            }}
          >
            <img src={close} className="close" />
          </button>
          <ul>
            
            <a href="/" onClick={()=>setModal(false)}>
              <button >
                <li>QUEM SOMOS</li>
              </button>
            </a>
            <br />
            <a href="/" onClick={()=>setModal(false)}>
            <button>
              <li>FEEDBACKS</li>
            </button>
            </a>
            <br />
            <a href="/" onClick={()=>setModal(false)}>
            <button>
              <li>INDICAÇÕES</li>
            </button>
            </a>
            <br />
            <a href="/" onClick={()=>setModal(false)}>
            <button>
              <li>CONTATO</li>
            </button>
            </a>
          </ul>
        </div>
      )}
      <div className="nav">
        <div className="barraNav">
                <div className="logo">
            <a href="/">
          <img src={logo} alt="logo" />
          <h5>HONÓRIO TUR</h5>
                </a>
            </div>

          <ul>
            <a href="/" >
              <button className="quemSomos">
                <li>QUEM SOMOS</li>
              </button>
            </a>
            
            <a href="/" >
              <button className="feedbacks">
                <li>FROTA</li>
              </button>
            </a>
            <a href="/" >
              <button className="indicacoes">
                <li>INDICAÇÕES</li>
              </button>
            </a>
            <a href="/">
              <button  className="contact">
                <li>CONTATO</li>
              </button>
            </a>
            <a href="https://api.whatsapp.com/send/?phone=5582996030242&text=Olá" className="inconB" >
                <img src={whatsapp} alt="whatsapp" className="incon"/>
            </a>
                  <a href="https://www.instagram.com/honorioturmaceioal?utm_source=qr&igsh=MXB2c3EycGN2OThrcg==" className="inconB" >
                <img src={instagram} alt="whatsapp" className="incon"/>
            </a>
          </ul>
          <div className="socialCont">
          <a href="https://api.whatsapp.com/send/?phone=5582996030242&text=Olá" className="inconB" >
                <img src={whatsapp} alt="whatsapp" className="incon"/>
            </a>
            <a href="https://www.instagram.com/honorioturmaceioal?utm_source=qr&igsh=MXB2c3EycGN2OThrcg==" className="inconB" >
                <img src={instagram} alt="whatsapp" className="incon"/>
            </a>
          <button
            onClick={() => {
              setModal(true);
            }}
            className="btB"
          >
            <img src={menu} className="menu" />
          </button>
              </div>
        </div>
      </div>
          </div>
    </>
  );
}

export default Navbar;
