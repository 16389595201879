
import "./style.css"
import { Swiper, SwiperSlide } from "swiper/react";
import corolaf from "../../assets/cars/corola.PNG"
import corolaL from "../../assets/cars/corola l.PNG"
import corolaC from "../../assets/cars/corola c.PNG"
import doblo from "../../assets/cars/doblo.PNG"
import dobloC from "../../assets/cars/doblo c.PNG"
import kwidf from "../../assets/cars/kwid.PNG"
import kwidl from "../../assets/cars/kwid l.PNG"
import kwidc  from "../../assets/cars/kwid c.PNG"
import spinf from "../../assets/cars/spin f.webp"
import spin from "../../assets/cars/spin.webp"
import buzz from "../../assets/cars/onibus l.webp"
import buzzl from "../../assets/cars/onibus.webp"
import furgao from "../../assets/cars/furgao frent.webp"
import furgaol from "../../assets/cars/furgao lado.webp"
import renat from "../../assets/cars/SSSSSS.png"
import sv8 from "../../assets/cars/SV8.png"


import Navbar from "../navCar";



const App = () => {
  const sprinterQ = [
    {img:sv8}
  ]

  const renalt = [
    {img:renat}
  ]
  const corola = [
    {
   img:corolaf},
    {
      
     img:corolaL
    },
    {
      
     img:corolaC
    }
  ]
  const dobloCar = [
    {
      img:doblo
    },
    {
      img:dobloC
    }
  ]
  const kwid = [
    { img:kwidf,
    },
    { img:kwidl,
    },
    { img:kwidc,
    },
  ]
  const buzzCar = [
    {
    img:buzz},
    {img:buzzl
    }
  ]
  const spinCar = [
    {
    img:spinf
  },
  {img:spin
},

  ]
  const furgaoCar = [
    {
    img:furgao
  },
  {img:furgaol
  }
  ]
   return (
    <>
    <Navbar/>
      <div className="frota">
         <br />
          <h2>Explore o luxo exclusivo em nossos carros . Desfrute do conforto, estilo e privacidade inigualáveis. Garanta já sua experiência única!</h2>
        <div className="carrosLeves">
          <ul>
        <li>
          <h1>Corolla</h1>
        <Swiper slidesPerView={1} pagination={true} navigation={true} className="privativeCars">
        {corola.map((item)=>(
          <SwiperSlide>
              <img src={item.img} alt="" />
            </SwiperSlide> 
          ))}
          </Swiper>
          <h3>Viaje com elegância e confiança: Corolla Jornadas, a escolha perfeita para suas experiências inesquecíveis!</h3>
          </li>
          <li>
            <h1>Doblo</h1>
            <Swiper slidesPerView={1} pagination={true} navigation={true} className="privativeCars">
        {dobloCar.map((item)=>(
          <SwiperSlide>
              <img src={item.img} alt="" />
            </SwiperSlide> 
          ))}
          </Swiper>
          <h3>Amplie seus horizontes com o Doblo Adventure: conforto, versatilidade e diversão em cada rota, uma viagem feita para a sua liberdade!</h3>
          </li>
          <li>
            <h1>Renault Kwid</h1>
            <Swiper slidesPerView={1} pagination={true} navigation={true} className="privativeCars">
        {kwid.map((item)=>(
          <SwiperSlide>
              <img src={item.img} alt="" />

            </SwiperSlide> 
          ))}
          </Swiper>
          <h3>Descubra o mundo com estilo e conforto: Renault Kwid Viagens, onde cada estrada se torna uma aventura inesquecível!</h3>
          </li>
          </ul>
          </div>
          <div className="carrosPesados">
          <ul>
        <li>
          <h1>Micro-ônibus</h1>
        <Swiper slidesPerView={1} pagination={true} navigation={true} className="privativeCars">
        {buzzCar.map((item)=>(
          <SwiperSlide>
              <img src={item.img} alt="" />
            </SwiperSlide> 
          ))}
          </Swiper>
          <h3>Micro-Ônibus Excellence: Mais que um veículo, uma experiência de viagem completa. Conforto, segurança e versatilidade para levar você e seus passageiros a novos destinos com estilo e tranquilidade. Sua jornada, nossa prioridade!</h3>
          </li>
          <li>
            <h1>Chevrolet Spin</h1>
            <Swiper slidesPerView={1} pagination={true} navigation={true} className="privativeCars">
        {spinCar.map((item)=>(
          <SwiperSlide>
              <img src={item.img} alt="" />
            </SwiperSlide> 
          ))}
          </Swiper>
          <h3>Spin Adventures: O seu veículo para viagens em família, onde cada curva se transforma em momentos inesquecíveis. Espaço, conforto e diversão, sua jornada começa com o Chevrolet Spin!</h3>
          </li>
          <li>
            <h1>Renault Master</h1>
            <Swiper slidesPerView={1} pagination={true} navigation={true} className="privativeCars">
        {renalt.map((item)=>(
          <SwiperSlide>
              <img src={item.img} alt="" />

            </SwiperSlide> 
          ))}
          </Swiper>
          <h3>Renault-Master Viagens: Transformando Cada Rota em Uma Jornada Inesquecível. Conforto, Segurança e Destinos Surpreendentes, Sua Aventura Começa Aqui!</h3>
          </li>
          <li>
            <h1>Mercedes sprinter 515 & 415</h1>
            <Swiper slidesPerView={1} pagination={true} navigation={true} className="privativeCars">
        {sprinterQ.map((item)=>(
          <SwiperSlide>
              <img src={item.img} alt="" />

            </SwiperSlide> 
          ))}
          </Swiper>
          <h3>Sprinter 515: A Jornada da Excelência. Viagens com Conforto, Eficiência e Destinos Memoráveis. Embarque nessa Experiência Inigualável, Sua Aventura, Nossa Missão!</h3>
          </li>
          </ul>
          </div>
          </div>
        </>
  );
};

export default App;
