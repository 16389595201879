import React from "react";
import "./style.css";
import wavetop from "../../assets/svg/wavedown.svg";
import { Swiper, SwiperSlide } from "swiper/react";

import waveUp from "../../assets/svg/upWave.svg";
import car from "../../assets/imgs/furgao lado.webp"

function Comentarios() {
  return (
    <>
      <div className="comments">
        <img src={waveUp} />
        <h3 className="titleh3">Frota</h3>
      <img src={car} alt="" className="car"/>
      <a href="/frota">
        <button>Conheça nossa Frota !!</button>
      </a>
      </div>
        <img src={wavetop} alt="" className="wv"/>
    </>
  );
}

export default Comentarios;
