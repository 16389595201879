import Home from "../home";
import Conhecer from "../Conheca";
import Comentarios from "../comentarios";
import Indicacao from "../indicacoes";
import Contato from "../contato";
function App() {
  return (
    <>
      
   <Home />
  <section id="quemSomos">
        <Conhecer />
      </section>
      <section id="feedback">
      <Comentarios />
      </section>
      <section id="indicacoes">
        <Indicacao />
      </section>
      <section id="contato">
        <Contato />
      </section>
    </>
  );
}

export default App;
